import { toast } from "react-toastify";

export const checkOnline = (): boolean => {
  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    toast.error("You are offline");
    return false;
  }
  return true;
};
