import axios from "axios";
import { getRefreshToken, setRefreshToken, removeAllUserData } from "../utils";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/api/";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        removeAllUserData();
        return Promise.reject(error);
      }

      try {
        const response = await axios.post("/auth/refresh", { refreshToken });
        const { refreshToken: newRefreshToken } = response.data;

        setRefreshToken(newRefreshToken);

        return axios(originalRequest);
      } catch (refreshError) {
        removeAllUserData();
        return Promise.reject(refreshError);
      }
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      window.location.href = "/login";
      removeAllUserData();
    }

    return Promise.reject(error);
  }
);

const errorMessagesMap = [
  {
    key: "phoneNumber must be a valid phone number",
    message:
      "Phone number must be a valid phone number. Please enter in country area code ie +1999-999-9999 not 999-999-9999.",
  },
];

const errorsMessage = (error: any): void => {
  const mistake = error.response.data.message;
  if (typeof mistake === "string") {
    toast.error(mistake);
    return;
  }

  if (Array.isArray(mistake)) {
    let mistakes = [...mistake];

    mistakes = mistakes.map((err) => {
      const foundError = errorMessagesMap.find((item) => item.key === err);
      return foundError ? foundError.message : err;
    });

    toast.error(mistakes.join("\n"), { autoClose: 7000 });
    return;
  }

  toast.error("Something went wrong");
};

export function getRequest(params: any) {
  return axios
    .get(params.url, {
      ...params,
      headers: {
        ...params.headers,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      errorsMessage(error);
      throw error;
    });
}

export function postRequest(params: any) {
  return axios
    .post(params.url, params.data, {
      ...params,
    })
    .then((response) => response.data)
    .catch((error) => {
      errorsMessage(error);
      throw error;
    });
}

export function patchRequest(params: any) {
  return axios
    .patch(params.url, params.data)
    .then((response) => response.data)
    .catch((error) => {
      errorsMessage(error);
      throw error;
    });
}

export function deleteRequest(params: any) {
  return axios
    .delete(params.url, { data: params.data })
    .then((response) => response.data)
    .catch((error) => {
      errorsMessage(error);
      throw error;
    });
}
