import { whiteText } from "../../utils";
import styled from "@emotion/styled";

export const TextAreaCss = styled.div`
  color: ${whiteText};
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
