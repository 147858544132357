import Button from "../../components/Button";
import Section from "../../components/Section";
import { RegisterForm } from "../../components/Forms";
import {
  Header,
  HomeWrap,
  HomeCss,
  HeroTitle,
  HeaderHeroWrap,
  HeroWrap,
  HeroTextWrap,
  HeroText,
  Sub,
  Title,
  RSectionWrap,
  LSectionWrap,
  Text,
  RImg,
  LImg,
  B,
  ImgWrap,
  AboutUsText,
  AboutUsSubtitle,
  AboutUsTitle,
  AboutUsDescription,
  Svg,
  AboutUsMission,
  AboutUsLogo,
  AboutUsWrap,
  Footer,
  NavMenu,
  TypoA,
  TypoLink,
  HeroButtons,
} from "./Home.styled";
import sprite from "../../assets/icons/symbol-defs.svg";
import f1 from "../../assets/f1.png";
import f2 from "../../assets/f2.png";
import f3 from "../../assets/f3.png";
import f4 from "../../assets/f4.png";
import logo from "../../assets/logoFind.png";
import questions from "../../assets/questions";
import QuestionDropDown from "../../components/QuestionDropDown";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const onGetAppClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/track-my-jobs/epcdbofkmkklkceiingophoenelfkmke?authuser=1&hl=en&pli=1",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <HomeWrap>
      <main>
        <HomeCss>
          <HeaderHeroWrap>
            <Header>
              <div>
                <img src={logo} alt="logo" />
                <svg width="211" height="33">
                  <use xlinkHref={`${sprite}#dummy-logo`}></use>
                </svg>
              </div>

              <NavMenu>
                <TypoA href="/#features">Features</TypoA>
                <TypoA href="/#about-us">About Us</TypoA>
                <TypoA href="/#faq">FAQ</TypoA>

                <TypoLink onClick={() => navigate("/payment-plans")}>
                  Pricing Plans
                </TypoLink>

                <TypoLink onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </TypoLink>
              </NavMenu>
            </Header>

            <HeroWrap>
              <HeroTitle>
                Effortlessly Manage Your Job Hunt with Our Easy-to-Use Board
              </HeroTitle>

              <HeroTextWrap>
                <HeroText>
                  Take control of your job search with TrackMyJobs. Our
                  user-friendly job tracking board simplifies the process,
                  allowing you to save notes, easily locate job postings, and
                  view detailed job descriptions. Stay organized and efficient
                  every step of the way.
                </HeroText>
                <HeroButtons>
                  <Button
                    as="a"
                    href="/#register"
                    text="Get Started"
                    btnType="filled"
                    bold
                  />

                  <Button
                    onClick={onGetAppClick}
                    text="Get App"
                    btnType="filled"
                    bold
                  />

                  <Button
                    onClick={() => navigate("/login")}
                    text="Login"
                    bold
                  />
                </HeroButtons>
              </HeroTextWrap>
            </HeroWrap>
          </HeaderHeroWrap>

          <Section id="features">
            <RSectionWrap>
              <div>
                <div>
                  <Sub>Features</Sub>
                  <Title>
                    Easy to use board that simplifies your job search journey
                    while keeping your organized Save notes about each job 
                  </Title>
                </div>
                <div>
                  <Text>
                    Trackmyjob’s board offers a
                    <B>
                      visual and organized way to manage your job applications,
                    </B>
                    allowing you to track progress at a glance. With
                    drag-and-drop functionality and customizable columns, it
                    streamlines your jobtracking and enhances efficiency.
                    <B>
                      Easily prioritize certain jobs and stay focused, ensuring
                      no step in your job search process is overlooked.
                    </B>
                  </Text>
                </div>
              </div>

              <ImgWrap>
                <RImg src={f1} alt="" height={549} />
              </ImgWrap>
            </RSectionWrap>
          </Section>

          <Section>
            <LSectionWrap>
              <ImgWrap>
                <LImg src={f2} alt="" height={429} />
              </ImgWrap>

              <div>
                <div>
                  <Sub>Features</Sub>
                  <Title>
                    Easy to find links to find original job posting + in depth
                    job description
                  </Title>
                </div>
                <div>
                  <Text>
                    Trackmyjobs job description
                    <B>
                      saving, and note-taking features keep all essential
                      information centralized and easily accessible.
                    </B>
                    Quickly reference job details and add personalized notes to
                    track your thoughts, interview insights, and follow-up
                    actions. This helps you stay organized, well-prepared, and
                    improves your chances of landing the job.
                  </Text>
                </div>
              </div>
            </LSectionWrap>
          </Section>

          <Section>
            <RSectionWrap>
              <div>
                <div>
                  <Sub>Features</Sub>
                  <Title>
                    Streamlined chrome extension to add jobs into your job board
                  </Title>
                </div>
                <div>
                  <Text>
                    Trackmyjobs Chrome extension
                    <B>
                      allows you to quickly add job listings directly from any
                      website to your job board with just a few clicks, saving
                      time and effort.
                    </B>
                    It auto-fills key details like job title, company, and
                    location, ensuring accuracy and consistency.
                    <B>
                      This seamless integration keeps your job search organized
                      and efficient, letting you focus on applying and preparing
                      for interviews.
                    </B>
                  </Text>
                </div>
              </div>

              <ImgWrap>
                <LImg src={f3} alt="" height={399} />
              </ImgWrap>
            </RSectionWrap>
          </Section>

          <Section>
            <LSectionWrap>
              <ImgWrap>
                <LImg src={f4} alt="" height={429} />
              </ImgWrap>

              <div>
                <div>
                  <Sub>Features</Sub>
                  <Title>
                    Selective Networking please highlight this especially
                  </Title>
                </div>
                <div>
                  <Text>
                    Trackmyjobs
                    <B>
                      selective networking feature connects you with the right
                      people by identifying contacts within your LinkedIn
                      network who are part of the relevant team for the job
                      you're applying for.
                    </B>
                    It also suggests potential contacts outside your network,
                    expanding your reach. This targeted approach saves you time
                    and effort, helping you focus on valuable connections.
                    <B>
                      Enhance your job search by networking efficiently and
                      increasing your chances of securing the desired position.
                    </B>
                  </Text>
                </div>
              </div>
            </LSectionWrap>
          </Section>

          <Section black rounded id="about-us">
            <AboutUsWrap>
              <AboutUsLogo>
                <svg width="437" height="103">
                  <use xlinkHref={`${sprite}#dummy-logo`}></use>
                </svg>
              </AboutUsLogo>

              <div>
                <AboutUsText>
                  <AboutUsSubtitle>Our Dream and Purpose</AboutUsSubtitle>
                  <AboutUsTitle>About Us</AboutUsTitle>

                  <AboutUsDescription>
                    In a world where finding the right job becomes harder and
                    harder, TrackMyJobs was created to help the average job
                    hunter achieve success and get the job.
                    <B>
                      Our platform simplifies the job search process, making it
                      more efficient and less stressful.
                    </B>
                    We provide tools to organize your applications, track your
                    progress, and connect with the right people.
                  </AboutUsDescription>
                </AboutUsText>

                <AboutUsMission>
                  <Svg width="54" height="32">
                    <use xlinkHref={`${sprite}#paws`}></use>
                  </Svg>
                  At TrackMyJobs, our mission is to empower job seekers to reach
                  their career goals with confidence and ease.
                </AboutUsMission>
              </div>
            </AboutUsWrap>
          </Section>

          <Section id="faq">
            <div>
              <Title>Commonly asked questions</Title>
              <ul>
                {questions.map((question) => {
                  return (
                    <li key={question.q}>
                      <QuestionDropDown q={question} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Section>

          <Section black rounded id="register">
            <RegisterForm />
          </Section>
        </HomeCss>
      </main>
      {
        //TODO make this documents
      }
      <Footer>
        <span>©2024 Allright Serve. Track My Job</span>
        {/* <div>
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div> */}
      </Footer>
    </HomeWrap>
  );
}

export default Home;
