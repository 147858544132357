import React from "react";
import { Input, TextInputWrap, TextInputLabel, Svg } from "./Modal.styled";

type TextInputProps = {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  icon: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput: React.FC<TextInputProps> = ({
  label,
  icon,
  value,
  onChange,
  ...props
}) => {
  return (
    <TextInputWrap>
      <TextInputLabel htmlFor={props.name}>{label}</TextInputLabel>
      <Input value={value} onChange={onChange} {...props} />
      <Svg>
        <use xlinkHref={icon}></use>
      </Svg>
    </TextInputWrap>
  );
};

export default TextInput;
