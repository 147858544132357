import styled from "@emotion/styled";
import {
  darkText,
  green,
  networkBorderColor,
  secondaryDark,
  secondaryWhiteText,
  tertiaryDark,
  whiteText,
} from "../../utils";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Form = styled.form`
  background: ${secondaryDark};
  border-radius: 8px;
  width: 1028px;
  height: 750px;
  border-radius: 16px;
`;

export const Header = styled.div`
  padding: 30px 40px 22px 40px;
  display: flex;
  justify-content: space-between;
`;

export const Info = styled.div``;

export const InfoTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Company = styled.p`
  font-size: 20px;
`;
export const Buttons = styled.div`
  display: flex;
  gap: 21px;
`;

export const Tabs = styled.div`
  display: grid;
  margin-bottom: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`;

export const Tab = styled.button<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? tertiaryDark : "transparent")};
  color: ${({ isActive }) => (isActive ? whiteText : tertiaryDark)};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isActive }) =>
    isActive
      ? `border-bottom: 1px solid ${tertiaryDark};
  border-top: 1px solid ${tertiaryDark};`
      : `border-bottom: 0.5px solid ${whiteText};
      border-top: 0.5px solid ${whiteText};`}

  & > svg {
    margin-right: 8px;
    fill: ${({ isActive }) => (isActive ? green : tertiaryDark)};
  }
`;

export const Content = styled.div`
  margin-top: 20px;
  padding: 0 144px 33px 144px;
`;

//!
export const FormWrap = styled.div``;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FirstWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 33px;
`;

export const TextInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Svg = styled.svg`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 16px;
  bottom: 5px;
  transform: translateY(-50%);
  fill: ${darkText};
`;

export const TextInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 12px 24px;
  padding-left: 52px;
  outline: none;

  margin: 0;
  height: 48px;
  border: 1px solid ${secondaryWhiteText};
  border-radius: 48px;
  color: ${darkText};
  font-size: 16px;
`;

export const TextAreaCss = styled.div`
  & > .rsw-editor {
    padding: 24px;
    background-color: ${whiteText};
    color: ${darkText};
    border-radius: 24px;
    height: 200px;
    overflow-y: auto;

    & > .rsw-ce {
      outline: none;
    }

    & > .rsw-toolbar {
      display: none;
    }
  }
`;

export const Error = styled.div`
  color: red;
  margin-top: 5px;
`;

export const NotesTextArea = styled.textarea`
  width: 100%;
  height: 530px;
  resize: none;
  padding: 16px;
  border-radius: 16px;
  outline: none;
`;

export const NetworkList = styled.ul`
  height: 530px;
  overflow: auto;
  padding: 0 20px;
`;

export const NetworkItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: .5px solid ${networkBorderColor}};
`;

export const NetworkImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto 0;
  margin-right: 30px;
`;

export const NetworkWrap = styled.li`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  column-gap: 20px;
  margin-bottom: 20px;
`;

export const NetworkingName = styled.p`
  font-weight: 600;
  margin-bottom: 10px;
`;

export const NetworkingLinks = styled.a`
  color: ${whiteText};
`;

export const NetworkingLinksText = styled.span`
  text-decoration: underline;
`;

export const NetworkingLocation = styled.a`
  color: ${whiteText};
`;
