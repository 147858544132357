import React, { ReactNode } from "react";
import { SectionCss } from "./Section.styled";
type SectionProps = {
  children: ReactNode;
  black?: boolean;
  rounded?: boolean;
  id?: string;
};

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  children,
  black = false,
  rounded = false,
  id,
}) => {
  return (
    <SectionCss rounded={rounded} black={black} id={id}>
      {children}
    </SectionCss>
  );
};

export default Section;
