import { useEffect } from "react";
import JobsList from "../../components/JobsList";
import { useJobsStore } from "../../store";
import { getUserId } from "../../utils";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { loading, getJobsInfo } = useJobsStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = getUserId();

    if (userId) {
      getJobsInfo(userId);
    } else {
      navigate("/login");
    }
    document.title = "Track My Jobs";
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <h2>Loading</h2>;
  }

  return (
    <>
      <JobsList />
    </>
  );
};

export default Dashboard;
