import styled from "@emotion/styled";
import { green, tertiaryDark, whiteText } from "../../utils";
import { NavLink } from "react-router-dom";

export const HeaderCss = styled.header`
  padding: 32px 53px 21px 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid ${whiteText};
`;

export const Logo = styled(NavLink)`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  color: ${whiteText};
`;

export const LogoStrong = styled.strong`
  font-family: Poppins;
  font-size: 33px;
  font-weight: 900;
  color: ${green};
`;

export const NavMenu = styled.nav`
  display: flex;
  gap: 20px;
`;

export const TypoA = styled.a`
  color: ${tertiaryDark};
  font-size: 20px;
  font-weight: 400;
  &:hover {
    color: ${whiteText};
    font-weight: 700;
  }
`;

export const TypoLink = styled.p`
  color: ${tertiaryDark};
  font-size: 20px;
  font-weight: 400;
  &:hover {
    color: ${whiteText};
    font-weight: 700;
  }
`;
