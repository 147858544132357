import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  LayoutContainer,
  ContentContainer,
  MainContent,
} from "./Layout.styled";

export const Layout = () => {
  return (
    <LayoutContainer>
      <Header />
      <MainContent>
        <Sidebar />
        <ContentContainer>
          <Suspense fallback={"<h1>Loading</h1>"}>
            <Outlet />
          </Suspense>
        </ContentContainer>
      </MainContent>
    </LayoutContainer>
  );
};
