import React, { useState } from "react";
import JobCard from "../JobCard";
import { useJobsStore } from "../../store";
import {
  Wrap,
  List,
  Column,
  TitleWrap,
  ButtonWrap,
  Button,
  Title,
  CardsList,
  CardsItem,
  EmptyMessage,
} from "./JobsList.styled";
import AddStatus from "../AddStatus";
import { JobDetails, JobDetailsList } from "../../types";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import EditModal from "../Modal/EditJobModal";
import CreateModal from "../Modal/CreateJobModal";

const JobsList: React.FC = () => {
  const {
    deleteJobStatus,
    deleteManyJob,
    updateJobOrders,
    getJobById,
    moveBetweenColumns,
    jobs,
    statuses,
  } = useJobsStore((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableJob, setEditableJob] = useState<JobDetails | null>(null);
  const [statusForCard, setStatusForCard] = useState<string | null>(null);

  const sortJobsByOrder = (jobs: JobDetailsList) => {
    const sortedJobs = [...jobs].sort((a, b) => {
      if (a.order === null) return 1;
      if (b.order === null) return -1;
      return (a.order as number) - (b.order as number);
    });

    return sortedJobs;
  };

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    //!between column
    if (destination.droppableId !== source.droppableId) {
      const job = getJobById(draggableId);

      //update jobs status
      if (job) {
        //update orders
        const columnJobs = [...jobs].filter((job) => {
          return job.jobStatusId === destination.droppableId;
        });

        const jobsToSplice = [...columnJobs];
        jobsToSplice.splice(destination.index, 0, job);

        let orders: { order: number; jobId: string }[] = [];

        jobsToSplice.forEach(({ jobId }, index) => {
          orders.push({ order: index, jobId });
        });

        moveBetweenColumns(
          draggableId,
          {
            ...job,
            jobStatusId: destination.droppableId,
          },
          orders
        );
      }
    }

    //!same column
    if (
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      const columnJobs = [...jobs].filter((job) => {
        return job.jobStatusId === source.droppableId;
      });

      const columnJobForSplice = [...columnJobs];
      const draggableItem = columnJobs[source.index];

      columnJobForSplice.splice(source.index, 1);

      columnJobForSplice.splice(destination.index, 0, draggableItem);
      let orders: { order: number; jobId: string }[] = [];

      columnJobForSplice.forEach(({ jobId }, index) => {
        orders.push({ order: index, jobId });
      });

      updateJobOrders(orders);
    }
  };

  const handleModalOpen = ({
    job = null,
    jobStatusId = null,
  }: {
    job?: JobDetails | null;
    jobStatusId?: string | null;
  }) => {
    setIsModalOpen(true);
    if (job) {
      setEditableJob(job);
    }
    if (jobStatusId) {
      setStatusForCard(jobStatusId);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (editableJob) {
      setEditableJob(null);
    }
    if (statusForCard) {
      setStatusForCard(null);
    }
  };

  const handleDeleteClick = (jobStatusId: string) => {
    const jobsToDelete = jobs
      .filter((job) => {
        return job.jobStatusId === jobStatusId;
      })
      .map(({ jobId }) => {
        return { id: jobId };
      });

    if (jobsToDelete.length) {
      deleteManyJob(jobsToDelete);
    }
    deleteJobStatus(jobStatusId);
  };

  return (
    <>
      {isModalOpen && editableJob && (
        <EditModal job={editableJob} onClose={() => handleModalClose()} />
      )}

      {isModalOpen && statusForCard && (
        <CreateModal
          jobStatusId={statusForCard}
          onClose={() => handleModalClose()}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Wrap>
          <List>
            {statuses.map((status) => (
              <Column key={status.jobStatusId}>
                <TitleWrap>
                  <Title>{status.jobStatus}</Title>
                  <ButtonWrap>
                    {status.jobStatusId !== "none" && (
                      <Button
                        type="button"
                        onClick={() => handleDeleteClick(status.jobStatusId)}
                      >
                        Delete status
                      </Button>
                    )}

                    <Button
                      type="button"
                      onClick={() =>
                        handleModalOpen({ jobStatusId: status.jobStatusId })
                      }
                    >
                      + Add card
                    </Button>
                  </ButtonWrap>
                </TitleWrap>

                <Droppable droppableId={status.jobStatusId}>
                  {(provided) => (
                    <CardsList
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {jobs.filter(
                        (job) => job.jobStatusId === status.jobStatusId
                      ).length === 0 ? (
                        <EmptyMessage>No jobs</EmptyMessage>
                      ) : (
                        sortJobsByOrder(jobs)
                          .filter(
                            (job) => job.jobStatusId === status.jobStatusId
                          )
                          .map((job, index) => {
                            return (
                              <Draggable
                                key={job.jobId}
                                draggableId={job.jobId}
                                index={index}
                              >
                                {(provided) => (
                                  <CardsItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <JobCard
                                      job={job}
                                      onOpen={() => {
                                        handleModalOpen({ job });
                                      }}
                                    />
                                  </CardsItem>
                                )}
                              </Draggable>
                            );
                          })
                      )}
                      {provided.placeholder}
                    </CardsList>
                  )}
                </Droppable>
              </Column>
            ))}

            <AddStatus />
          </List>
        </Wrap>
      </DragDropContext>
    </>
  );
};

export default JobsList;
