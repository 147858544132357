const questions = [
  {
    q: "What makes Trackmyjobs different from other job tracking apps?",
    a: "Trackmyjobs offers a unique combination of Kanban boards, selective networking, and seamless Chrome integration to streamline your job search process.",
  },
  {
    q: "Is there a limit to the number of jobs I can track?",
    a: "When you apply for trackmyjobs premium you unlock the ability to add unlimited jobs.While with the standard version you are allowed to add 30 jobs into your board",
  },
  {
    q: "How does the selective networking feature work?",
    a: "The selective networking feature identifies and suggests connections within your LinkedIn network who are part of the relevant team for the job you're applying for, as well as potential contacts outside your network",
  },
  {
    q: "How do I add jobs using the Chrome extension?",
    a: "Simply click on the Trackly extension icon while viewing a job listing, and the extension will automatically capture and fill in the job details for you.",
  },
  {
    q: "How can I track the progress of my applications?",
    a: "The Kanban board visually displays the status of each application, helping you track your progress from 'Saved' to 'Applied' to 'Interviewing' to 'Offers'.",
  },
  {
    q: "Can I customize the Kanban board stages?",
    a: "Yes, you can add, remove, or rename columns to fit your unique job search process.",
  },
];

export default questions;
