import styled from "@emotion/styled";
import { green, whiteText } from "../../utils/colors";

interface TextButtonCssProps {
  bold: boolean;
  height: string;
  width: string;
  btnType: "filled" | "empty";
}

export const TextButtonCss = styled.button<TextButtonCssProps>`
  background-color: ${({ btnType }) =>
    btnType !== "filled" ? "transparent" : green};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${green};

  color: ${whiteText};
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  outline: none;
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  ${(props) =>
    props.bold &&
    `
    font-weight:600;
  `}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:enabled {
    opacity: 1;
  }

  &:not(:disabled):hover {
    transform: scale(1.05);
  }
`;

export const Svg = styled.svg`
  fill: currentColor;
`;
