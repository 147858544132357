import styled from "@emotion/styled";
import {
  cardBackground,
  cardText,
  checkboxColor,
  green,
  headerBackground,
  mainDark,
  whiteText,
} from "../../utils";

export const Wrap = styled.div`
  overflow-y: scroll;
  height: 100vh;
  height: calc(100vh - 101.5px);
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HeroTextWrap = styled.div`
  text-align: center;
  margin-bottom: 84px;
`;

export const HeroTitle = styled.h1`
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const HeroSubtitle = styled.p`
  font-size: 20px;
`;

const PlanWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
  width: 100%;
  padding: 16px 24px;
  padding-right: 0;
`;

export const FreePlanWrap = styled(PlanWrap)`
  background-color: ${headerBackground};
`;

export const PremiumPlanWrap = styled(PlanWrap)`
  background-color: ${green};
  position: relative;
  justify-content: start;
`;

export const CheckSubWrap = styled.div`
  background-color: ${checkboxColor};
  height: 56px;
  border-radius: 8px;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const CheckSubText = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: ${whiteText};
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckSubTextActive = styled(CheckSubText)`
  background-color: ${whiteText};
  color: ${cardBackground};
  font-weight: 700;
`;

export const BestChoice = styled.span`
  position: absolute;
  top: 33px;
  right: 24px;
  background-color: ${mainDark};
  color: ${whiteText};
  border-radius: 13px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 18px;
`;

export const PremiumPlanCount = styled.span`
  font-size: 80px;
`;

export const PlanName = styled.h2`
  font-size: 40px;
  color: ${whiteText};
`;

export const FreePlanText = styled.p`
  font-size: 80px;
`;

export const PlanText = styled.span`
  font-size: 36px;
  color: ${whiteText};
`;

export const PlanTextWords = styled.p`
  margin-bottom: 10px;
`;

export const PremiumPlanText = styled(PlanText)``;

export const PlanCost = styled.span`
  color: ${whiteText};
`;

export const PremiumPlanCost = styled(PlanCost)``;

export const PlanCostBold = styled(PlanCost)`
  font-weight: 700;
`;

export const HeroWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
`;

export const Card = styled.div`
  width: 420px;
  background-color: ${cardBackground};
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
`;

export const FeatureList = styled.ul`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

export const FeatureTextWrap = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FeatureText = styled.span`
  font-size: 16px;
  color: ${cardText};
`;
