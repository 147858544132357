import styled from "@emotion/styled";
import { mainDark, tertiaryDark, whiteText } from "../../utils";

export const HomeWrap = styled.div`
  padding-top: 13px;
  background-color: ${whiteText};
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const HomeCss = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 0 12px;
  margin-bottom: 200px;
`;

export const Header = styled.header`
  width: 1300px;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  padding: 26px 66px;
  cursor: pointer;
  justify-content: space-between;
`;

export const NavMenu = styled.nav`
  display: flex;
  gap: 20px;
`;

export const TypoA = styled.a`
  color: ${tertiaryDark};
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${whiteText};
    font-weight: 700;
  }
`;

export const TypoLink = styled.p`
  color: ${tertiaryDark};
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${whiteText};
    font-weight: 700;
  }
`;

export const HeaderHeroWrap = styled.div`
  background-color: ${mainDark};
  border-radius: 36px;
`;
export const HeroWrap = styled.div`
  padding: 176px 33px 171px 76px;
  display: flex;
  gap: 77px;
`;

export const HeroTitle = styled.h1`
  font-size: 60px;
  font-weight: 700;
`;

export const HeroTextWrap = styled.div`
  min-width: 510px;
  max-width: 510px;
`;

export const HeroText = styled.p`
  font-size: 20px;
  color: ${tertiaryDark};
  margin-bottom: 58px;
  line-height: 150%;
`;

export const HeroButtons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;
`;

export const SectionWrap = styled.div`
  display: grid;
  gap: 15px;
`;

export const RSectionWrap = styled(SectionWrap)`
  grid-template-columns: 1.3fr 1fr;
`;

export const LSectionWrap = styled(SectionWrap)`
  grid-template-columns: 1fr 1.3fr;
`;

export const Sub = styled.p`
  font-size: 24px;
  font-style: italic;
  color: ${tertiaryDark};
  margin-bottom: 18px;
`;

export const Title = styled.h2`
  color: ${mainDark};
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  margin-bottom: 24px;
`;

export const Text = styled.p`
  width: 100%;
  line-height: 150%;
  font-size: 20px;
  color: ${tertiaryDark};
`;

export const B = styled(Text)`
  color: currentColor;
  font-weight: 700;
`;

export const ImgWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const RImg = styled(Img)`
  left: 0;
`;

export const LImg = styled(Img)`
  right: 0;
`;

export const AboutUsWrap = styled(LSectionWrap)`
  gap: 126px;
`;

export const AboutUsLogo = styled(ImgWrap)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AboutUsText = styled.div`
  display: flex;
  flex-direction: column;
  width: 656px;
  margin-bottom: 97px;
  color: ${whiteText};
`;

export const AboutUsSubtitle = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
  font-style: italic;
`;

export const AboutUsTitle = styled.h2`
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 8px;
`;

export const AboutUsDescription = styled.p`
  font-weight: 300;
  font-size: 20px;
  font-style: italic;
  color: ${whiteText};
  line-height: 150%;
`;

export const AboutUsMissionWrap = styled.div`
  height: 140px;
  position: relative;
  display: flex;
`;

export const Svg = styled.svg`
  fill: ${tertiaryDark};
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
`;

export const AboutUsMission = styled.div`
  position: relative;
  margin-left: 60px;
  width: 521px;
  font-weight: 300;
  font-size: 24px;
  font-style: italic;
  line-height: 150%;
`;

export const QuestionList = styled.ul`
  width: 1149px;
`;

export const QuestionItem = styled.li`
  width: 100%;
`;

export const Footer = styled.footer`
  padding: 0 60px;
  background-color: ${mainDark};
  height: 70px;
  display: flex;
  align-items: center;
`;
