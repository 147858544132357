import styled from "@emotion/styled";
import { secondaryWhiteText, whiteText } from "../../utils";

export const JobLinkText = styled.a`
  display: block;
  background-color: ${secondaryWhiteText};
  position: relative;
  color: ${whiteText};
  border-radius: 6px;
  padding: 12px;
  padding-left: 44px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 24px;
`;

export const Svg = styled.svg`
  fill: currentColor;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
`;
