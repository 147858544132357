import styled from "@emotion/styled";
import { whiteText } from "../../utils";

export const FormWrap = styled.div`
  width: 100%;
  padding: 0 150px;
  border-radius: 24px;
`;

export const FormWrapSettings = styled(FormWrap)`
  padding: 0;
  width: 100%;
`;

export const Form = styled.form`
  color: white;
`;

export const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  margin-bottom: 113px;
`;

export const FormTitle = styled.h1`
  text-align: center;
  font-weight: 700;
  font-size: 60px;
  margin-bottom: 63px;
`;
export const InputWrap = styled.div`
  position: relative;
`;

export const InputError = styled.div`
  position: absolute;
  left: 26px;
  bottom: -20px;
`;

export const FormInput = styled.input`
  background-color: ${whiteText};
  width: 100%;
  height: 56px;
  border-radius: 28px;
  padding: 16px 24px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 500px;
  margin: 0 auto;
`;
