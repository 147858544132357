import React, { useState } from "react";
import sprite from "../../assets/icons/symbol-defs.svg";
import {
  Backdrop,
  Form,
  Tabs,
  Tab,
  Content,
  Header,
  Info,
  Buttons,
  InfoTitle,
  Company,
  FormWrap,
  InputWrap,
  FirstWrap,
  TextInputLabel,
  NotesTextArea,
  NetworkList,
  NetworkItem,
  NetworkImg,
  NetworkingName,
  NetworkWrap,
  NetworkingLocation,
  NetworkingLinks,
  NetworkingLinksText,
} from "./Modal.styled";
import avatarPlaceholder from "../../assets/avatarPlaceholder.jpg";
import { JobDetails } from "../../types";
import Button from "../Button";
import InputText from "./InputText";
import TextArea from "./TextArea";
import { useJobsStore } from "../../store";
import * as Yup from "yup";
import { getUser } from "../../utils";

type ModalProps = { job: JobDetails; onClose: () => void };

const JobSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job title is required"),
  company: Yup.string().required("Company is required"),
  salary: Yup.string(),
  location: Yup.string(),
  description: Yup.string(),
  notes: Yup.string(),
  link: Yup.string().required("Link is required"),
});

type FormValues = Yup.InferType<typeof JobSchema>;

const Modal: React.FC<ModalProps> = ({ job, onClose }) => {
  const {
    jobId,
    jobTitle,
    company,
    description,
    salary,
    location,
    link,
    notes,
  } = job;
  const { subscription } = getUser();
  const { updateJob } = useJobsStore((state) => state);

  const [activeTab, setActiveTab] = useState<"edit" | "network" | "notes">(
    "edit"
  );

  const [values, setValues] = useState<FormValues>({
    jobTitle: jobTitle || "",
    company: company || "",
    salary: salary || "",
    location: location || "",

    description: description || "<h1>About Job</h1>",
    notes: notes || "",
    link: link || "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof FormValues, string>>
  >({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validateField(name as keyof FormValues, value);
  };

  const validateField = async (name: keyof FormValues, value: any) => {
    try {
      const schema = Yup.object().shape({ [name]: JobSchema.fields[name] });
      await schema.validate({ [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err: any) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
    }
  };

  const makeLabel = (label: string | null | undefined): string => {
    return label ? label : "Data not found";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await JobSchema.validate(values, { abortEarly: false });
      updateJob(jobId, values);
    } catch (err: any) {
      const validationErrors: any = {};

      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
      });

      setErrors(validationErrors);
    }
  };

  return (
    <Backdrop>
      <Form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
        <Header>
          <Info>
            <InfoTitle>{jobTitle}</InfoTitle>
            <Company>{company}</Company>
          </Info>

          <Buttons>
            <Button
              text="Back"
              width="120px"
              type="button"
              icon={`${sprite}#return`}
              onClick={onClose}
            />
            <Button
              text="Save"
              btnType={"filled"}
              width="120px"
              icon={`${sprite}#favorite`}
              type="submit"
            />
          </Buttons>
        </Header>

        <Tabs>
          <Tab
            type="button"
            isActive={activeTab === "edit"}
            onClick={() => setActiveTab("edit")}
          >
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#box_edit`}></use>
            </svg>
            Edit
          </Tab>

          <Tab
            type="button"
            isActive={activeTab === "network"}
            onClick={() => setActiveTab("network")}
          >
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#diversity`}></use>
            </svg>
            Network
          </Tab>

          <Tab
            type="button"
            isActive={activeTab === "notes"}
            onClick={() => setActiveTab("notes")}
          >
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#stylus`}></use>
            </svg>
            Notes
          </Tab>
        </Tabs>

        <Content>
          {activeTab === "edit" && (
            <FormWrap>
              <InputWrap>
                <FirstWrap>
                  <InputText
                    name="jobTitle"
                    label="Job Title"
                    icon={`${sprite}#work`}
                    value={values.jobTitle}
                    onChange={handleInputChange}
                  />
                  {errors.jobTitle && <div>{errors.jobTitle}</div>}
                  <InputText
                    name="company"
                    label="Company"
                    icon={`${sprite}#factory`}
                    value={values.company}
                    onChange={handleInputChange}
                  />
                  {errors.company && <div>{errors.company}</div>}
                </FirstWrap>

                <InputText
                  name="link"
                  label="Post URL"
                  icon={`${sprite}#link`}
                  value={values.link || ""}
                  onChange={handleInputChange}
                />
                {errors.link && <div>{errors.link}</div>}

                <FirstWrap>
                  <InputText
                    name="salary"
                    label="Salary"
                    icon={`${sprite}#universal_currency_alt`}
                    value={values.salary || ""}
                    onChange={handleInputChange}
                  />
                  {errors.salary && <div>{errors.salary}</div>}
                  <InputText
                    name="location"
                    label="Location"
                    icon={`${sprite}#distance`}
                    value={values.location || ""}
                    onChange={handleInputChange}
                  />
                  {errors.location && <div>{errors.location}</div>}
                </FirstWrap>

                <div>
                  <TextInputLabel>Description</TextInputLabel>
                  <TextArea
                    element={values.description}
                    onChanges={(val) => {
                      setValues({ ...values, description: val });
                      validateField("description", val);
                    }}
                  />
                  {errors.description && <div>{errors.description}</div>}
                </div>
              </InputWrap>
            </FormWrap>
          )}

          {activeTab === "notes" && (
            <NotesTextArea
              name="notes"
              value={values.notes}
              onChange={(e) => {
                setValues({ ...values, notes: e.target.value });
                validateField("notes", e.target.value);
              }}
            />
          )}

          {activeTab === "network" && subscription === "premium" && (
            <NetworkList>
              {job.networking.map(
                ({ fullName, linkedInUrl, avatar, jobTitle }) => {
                  return (
                    <NetworkItem>
                      <NetworkImg
                        src={avatar || avatarPlaceholder}
                        alt="profile avatar"
                      />
                      <div>
                        {<NetworkingName>{fullName}</NetworkingName>}
                        {
                          <NetworkingName>
                            {jobTitle && `Job title: ${jobTitle}`}
                          </NetworkingName>
                        }

                        <NetworkWrap>
                          {linkedInUrl ? (
                            <NetworkingLinks
                              href={linkedInUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Go to Socials
                            </NetworkingLinks>
                          ) : (
                            makeLabel(linkedInUrl)
                          )}
                        </NetworkWrap>

                        <NetworkingLocation>
                          {location ? (
                            <p>
                              Location:{" "}
                              <NetworkingLinksText>
                                {location}
                              </NetworkingLinksText>
                            </p>
                          ) : (
                            <p>Location: {makeLabel(location)}</p>
                          )}
                        </NetworkingLocation>
                      </div>
                    </NetworkItem>
                  );
                }
              )}
            </NetworkList>
          )}
        </Content>
      </Form>
    </Backdrop>
  );
};

export default Modal;
