export const setAccessToken = (accessToken: string) =>
  localStorage.setItem("accessToken", accessToken);

export const setRefreshToken = (refreshToken: string) =>
  localStorage.setItem("refreshToken", refreshToken);

export const setUserId = (id: string) => {
  localStorage.setItem("userId", id);
};

export const setUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const getAccessToken = () => localStorage.getItem("accessToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getUserId = () => localStorage.getItem("userId");
export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    try {
      return JSON.parse(user);
    } catch (error) {
      console.error("Error parsing userId from localStorage", error);
      return null;
    }
  }
  return null;
};

export const removeAccessToken = () => localStorage.removeItem("accessToken");
export const removeRefreshToken = () => localStorage.removeItem("refreshToken");
export const removeUserId = () => localStorage.removeItem("userId");
export const removeUser = () => localStorage.removeItem("user");

export const removeAllUserData = () => {
  removeAccessToken();
  removeRefreshToken();
  removeUserId();
  removeUser();
};
