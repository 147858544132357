import React, { useState } from "react";
import {
  Editor,
  ContentEditableEvent,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { TextAreaCss } from "./Modal.styled";

type TextAreaProps = {
  element?: string;
  onChanges: (element: string) => void;
};

const TextArea: React.FC<TextAreaProps> = ({ element = "", onChanges }) => {
  const [html, setHtml] = useState<string>(element);

  const onChange = (e: ContentEditableEvent) => {
    setHtml(e.target.value);
    onChanges(e.target.value);
  };

  return (
    <EditorProvider>
      <Toolbar style={{ display: "none" }}></Toolbar>
      <TextAreaCss>
        <Editor
          containerProps={{ style: { resize: "none" } }}
          value={html}
          onChange={onChange}
        />
      </TextAreaCss>
    </EditorProvider>
  );
};

export default TextArea;
