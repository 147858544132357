import React, { useState, useRef, useEffect } from "react";
import { JobDetails } from "../../types";
import JobLink from "../JobLink";
import sprite from "../../assets/icons/symbol-defs.svg";
import TextArea from "../TextArea";
import { useJobsStore } from "../../store";
import {
  CommonText,
  Title,
  Button,
  SvgMore,
  Wrap,
  InfoWrap,
  TextWrap,
  Svg,
  Menu,
  MenuItem,
} from "./JobCard.styled";

type JobCardProps = {
  job: JobDetails;
  onOpen: any;
};

const JobCard: React.FC<JobCardProps> = ({ job, onOpen }) => {
  const { jobId, jobTitle, company, description, salary, location, link } = job;
  const { deleteJob } = useJobsStore();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsMenuVisible(false);
    }
  };

  const handleEditClick = () => {
    onOpen(job);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Wrap>
        <Title>{jobTitle}</Title>
        <Button ref={buttonRef} onClick={handleButtonClick}>
          <SvgMore width="20" height="5">
            <use xlinkHref={`${sprite}#more`}></use>
          </SvgMore>
        </Button>

        <Menu ref={menuRef} isVisible={isMenuVisible}>
          <MenuItem onClick={() => deleteJob(jobId)}>Delete</MenuItem>
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        </Menu>

        <CommonText style={{ paddingBottom: "24px" }}>{company}</CommonText>
        <JobLink link={link} />

        <InfoWrap>
          <TextWrap>
            <Svg width="24" height="24">
              <use xlinkHref={`${sprite}#universal_currency_alt`}></use>
            </Svg>
            <CommonText>{salary}</CommonText>
          </TextWrap>

          <TextWrap>
            <Svg width="24" height="24">
              <use xlinkHref={`${sprite}#distance`}></use>
            </Svg>
            <CommonText>{location}</CommonText>
          </TextWrap>
        </InfoWrap>

        <Title>Description</Title>
        <TextArea element={description} onChanges={(e) => {}} />
      </Wrap>
    </>
  );
};

export default JobCard;
