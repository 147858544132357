import styled from "@emotion/styled";
import { mainDark, secondaryDark, tertiaryDark, whiteText } from "../../utils";

type MenuProps = { isVisible: boolean };

export const Wrap = styled.div`
  position: relative;
  background-color: ${mainDark};
  border-radius: 16px;
  padding: 16px;
`;

export const Title = styled.p`
  color: ${whiteText};
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  padding: 0;
  margin: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${tertiaryDark};

  &:focus,
  :hover {
    color: ${whiteText};
  }
`;

export const SvgMore = styled.svg`
  fill: currentColor;
`;

export const CommonText = styled.p`
  color: ${whiteText};
  font-size: 16px;
  margin-bottom: 4px;
`;

export const InfoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
  gap: 25px;
`;

export const TextWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Svg = styled.svg`
  fill: currentColor;
  margin-right: 6px;
`;

export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${secondaryDark};
  border-radius: 11px;
  z-index: 10;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

export const MenuItem = styled.button`
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${tertiaryDark};

  &:focus {
    color: ${whiteText};
  }
`;
