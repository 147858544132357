export const mainDark = "#070E0B";
export const tertiaryDark = "#4D5754";
export const secondaryDark = "#201F24";
export const green = "#29B878";
export const whiteText = "#ffffff";
export const secondaryWhiteText = "#4D5754";
export const darkText = "#0C201B";
export const headerBackground = "#232323";
export const networkBorderColor = "#F1F3F4";
export const cardBackground = "#121212";
export const cardText = "#929292";
export const checkboxColor = "#23AB6E";
export const questionColor = "#1E1E1E";
export const answerBackColor = "#E5F7EF";
export const endLinearColor = "#202020";
