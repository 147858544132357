import { useEffect } from "react";
import { RegisterForm } from "../../components/Forms";
import { Page, FormWrap } from "./Register.styled";
const Register = () => {
  useEffect(() => {
    document.title = "Register";
  });
  return (
    <Page>
      <FormWrap>
        <RegisterForm />
      </FormWrap>
    </Page>
  );
};

export default Register;
