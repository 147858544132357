import { useState } from "react";
import { LoginForm } from "../../components/Forms";
import { Page, FormWrap } from "./LoginPage.styled";
const LoginPage = () => {
  useState(() => {
    document.title = "Login";
  });

  return (
    <Page>
      <FormWrap>
        <LoginForm />
      </FormWrap>
    </Page>
  );
};

export default LoginPage;
