import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "../Header";
import {
  LayoutContainer,
  PaymentMainContent,
  PaymentContentContainer,
} from "./Layout.styled";

export const PaymentLayout = () => {
  return (
    <LayoutContainer>
      <Header />
      <PaymentMainContent>
        <PaymentContentContainer>
          <Suspense fallback={<h1>Loading</h1>}>
            <Outlet />
          </Suspense>
        </PaymentContentContainer>
      </PaymentMainContent>
    </LayoutContainer>
  );
};
