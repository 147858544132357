import { Alert, SidebarCss } from "./Sidebar.styled";
import sprite from "../../assets/icons/symbol-defs.svg";
import {
  LinksWrap,
  Wrap,
  Svg,
  Link,
  Payment,
  PaymentLink,
  PaymentSvg,
  PaymentText,
} from "./Sidebar.styled";
import { getUser } from "../../utils";

const Sidebar = () => {
  const user = getUser();

  const subEnd = new Date(Number(user.subscriptionEnd) * 1000 + 43200000);
  const today = new Date(new Date().getTime());

  return (
    <SidebarCss>
      <LinksWrap>
        <Wrap>
          <Svg width="32" height="32">
            <use width="32" height="32" xlinkHref={`${sprite}#tablet`}></use>
          </Svg>
          <Link to="/dashboard">Board</Link>
        </Wrap>
        {
          // TODO Make networking
          /* <Wrap>
          <Svg width="32" height="32">
            <use xlinkHref={`${sprite}#groups`}></use>
          </Svg>
          <Link to="/member">Member</Link>
        </Wrap> */
        }
        <Wrap>
          <Svg width="32" height="32">
            <use width="32" height="32" xlinkHref={`${sprite}#settings`}></use>
          </Svg>
          <Link to="/settings">Setting Workspace</Link>
        </Wrap>
      </LinksWrap>

      {user.subscription === "premium" && subEnd < today && (
        <Alert>
          Your paid subscription has expired, some data will be deleted in 5
          days
        </Alert>
      )}

      {user.subscription !== "premium" && (
        <div>
          <PaymentText>Interested for full version?</PaymentText>

          <Payment>
            <PaymentSvg width="24" height="24">
              <use xlinkHref={`${sprite}#flag`}></use>
            </PaymentSvg>

            <PaymentLink to="/payment-plans">Try Premium Plan</PaymentLink>
          </Payment>
        </div>
      )}
    </SidebarCss>
  );
};

export default Sidebar;
