import { UserLocalStorage } from "../types";
import { getAccessToken, getRefreshToken, getUserId } from "../utils";

export const useAuth = (): UserLocalStorage => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const userId = getUserId();

  return {
    accessToken,
    refreshToken,
    userId,
  };
};
