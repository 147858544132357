import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthStore } from "../../store";
import {
  FormWrap,
  FormTitle,
  Form,
  InputWrap,
  FormInput,
  InputError,
  ButtonsWrap,
  InputsWrap,
} from "./Forms.styled";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const LoginForm = () => {
  const login = useAuthStore((state) => state.login);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }, { setSubmitting }) => {
      await login(email, password);
      setSubmitting(false);
      navigate("/dashboard");
    },
  });

  return (
    <FormWrap>
      <FormTitle>Sign In</FormTitle>

      <Form onSubmit={formik.handleSubmit}>
        <InputsWrap>
          <InputWrap>
            <FormInput
              placeholder="Email"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <InputError>{formik.errors.email}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Password"
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <InputError>{formik.errors.password}</InputError>
            ) : null}
          </InputWrap>
        </InputsWrap>

        <ButtonsWrap>
          <Button
            text="Login"
            disabled={formik.isSubmitting}
            type="submit"
            width="100%"
            bold
            btnType="filled"
          />

          <Button
            text="To Register Page"
            type="button"
            width="100%"
            bold
            btnType="filled"
            onClick={() => {
              navigate("/register");
            }}
          />
        </ButtonsWrap>
      </Form>
    </FormWrap>
  );
};
