import styled from "@emotion/styled";
import { answerBackColor, questionColor, tertiaryDark } from "../../utils";

export const Question = styled.p`
  font-weight: 700;
  font-size: 20px;
  padding: 16px 0;
  color: ${questionColor};
  border-bottom: 1px solid ${tertiaryDark};
  cursor: pointer;
`;

export const Answer = styled.p`
  padding: 16px 48px;
  font-size: 20px;
  color: ${tertiaryDark};
  background-color: ${answerBackColor};
`;
