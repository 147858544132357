import { create } from "zustand";
import { JobDetailsList, JobDetails, JobStatusList, JobStatus } from "../types";
import {
  checkOnline,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../utils";
import { getUserId } from "../utils";
import { toast } from "react-toastify";
type JobsState = {
  jobs: JobDetailsList;
  statuses: JobStatusList;
  loading: boolean;
  addJob: (
    newJob: Omit<JobDetails, "jobId" | "order" | "networking">
  ) => Promise<void>;
  getJobs: (ownerId: string) => Promise<void>;
  getJobsInfo: (ownerId: string) => Promise<void>;
  updateJob: (jobId: string, updatedJob: Partial<JobDetails>) => Promise<void>;
  updateJobLocal: (jobId: string, updatedJob: Partial<JobDetails>) => void;
  deleteJob: (jobId: string) => void;
  deleteManyJob: (ids: { id: string }[]) => Promise<void>;
  updateJobOrders: (
    orders: { jobId: string; order: null | number }[]
  ) => Promise<void>;
  getJobById: (jobId: string) => JobDetails | undefined;
  moveBetweenColumns: (
    jobId: string,
    updatedJob: Partial<JobDetails>,
    orders: { jobId: string; order: null | number }[]
  ) => void;

  setStatuses: (jobs: JobStatusList) => void;
  getJobStatuses: (ownerId: string) => Promise<void>;
  updateJobStatus: (
    jobId: string,
    updatedJobStatus: Partial<JobDetails>
  ) => Promise<void>;
  addJobStatus: (data: Omit<JobStatus, "jobStatusId">) => Promise<void>;
  deleteJobStatus: (jobStatusId: string) => Promise<void>;
};

export const useJobsStore = create<JobsState>((set, get) => ({
  jobs: [],
  statuses: [],
  loading: false,

  addJob: async (newJob) => {
    if (!checkOnline()) {
      return;
    }

    await postRequest({
      url: "jobs",
      data: newJob,
    })
      .then(({ data }) => {
        toast.success("Job created");

        set((state) => ({
          jobs: [...state.jobs, data],
        }));
      })
      .catch((error) => {
        if (error.status === 400) {
          set((state) => ({
            jobs: [...state.jobs],
          }));
        }
      });
  },

  updateJob: async (jobId, updatedJob) => {
    if (!checkOnline()) {
      return;
    }
    const {
      jobStatusId,
      order,
      jobTitle,
      company,
      location,
      salary,
      description,
      notes,
      link,
    } = updatedJob;

    await patchRequest({
      url: `jobs/${jobId}`,
      data: {
        jobStatusId,
        order,
        jobTitle,
        company,
        location,
        salary,
        description,
        notes,
        link,
      },
    }).then((data) => {
      set(() => ({
        jobs: [...data.data],
      }));
    });
  },

  updateJobLocal: (jobId, updatedJob) => {
    set((state) => ({
      jobs: state.jobs.map((job) =>
        job.jobId === jobId ? { ...job, ...updatedJob } : job
      ),
    }));
  },

  updateJobOrders: async (orders) => {
    if (!checkOnline()) {
      return;
    }
    orders.map((order) => {
      return get().updateJobLocal(order.jobId, { order: order.order });
    });

    await patchRequest({
      url: "jobs/order/update",
      data: orders,
    }).then(({ data }) => {
      set({ jobs: data });
    });
  },

  moveBetweenColumns: (jobId, updatedJob, orders) => {
    orders.map((order) => {
      return get().updateJobLocal(order.jobId, { order: order.order });
    });

    get().updateJob(jobId, updatedJob);
    get().updateJobOrders(orders);
  },

  getJobs: async (ownerId) => {
    await getRequest({ url: `jobs/by-owner/${ownerId}` }).then(({ data }) => {
      set({
        loading: false,
        jobs: data,
      });
    });
  },

  getJobsInfo: async (ownerId) => {
    const state = useJobsStore.getState();

    await state.getJobs(ownerId);
    await state.getJobStatuses(ownerId);
  },

  deleteJob: async (jobId) => {
    if (!checkOnline()) {
      return;
    }

    await deleteRequest({ url: `jobs/${jobId}` }).then(() => {
      toast.success("Job deleted");

      set((state) => ({
        jobs: state.jobs.filter((job) => job.jobId !== jobId),
      }));
    });
  },

  deleteManyJob: async (ids) => {
    await postRequest({ url: `jobs/delete-many`, data: ids });

    set((state) => ({
      jobs: state.jobs.filter((job) => !ids.some((id) => id.id === job.jobId)),
    }));
  },

  getJobById: (jobId: string) => {
    const state = get();
    return state.jobs.find((job) => job.jobId === jobId);
  },

  setStatuses: (statuses) => set({ statuses }),

  getJobStatuses: async (ownerId) =>
    await getRequest({ url: `job-status/by-owner/${ownerId}` }).then(
      ({ data }) => {
        const userId = getUserId();

        if (userId) {
          set({
            loading: false,
            statuses: [
              { jobStatusId: "none", jobStatus: "Saved Jobs", ownerId: userId },
            ].concat(data),
          });
        }
      }
    ),

  updateJobStatus: async (jobStatusId, updatedJobStatus) => {
    if (!checkOnline()) {
      return;
    }

    set((state) => ({
      statuses: state.statuses.map((jobStatus) =>
        jobStatus.jobStatusId === jobStatusId
          ? { ...jobStatus, ...updatedJobStatus }
          : jobStatus
      ),
      loading: false,
    }));

    const {
      jobId,
      jobTitle,
      company,
      description,
      location,
      salary,
      ownerId,
      link,
      notes,
      order,
    } = updatedJobStatus;
    await patchRequest({
      url: `job-status/by-owner/${jobStatusId}`,
      data: {
        jobId,
        jobTitle,
        company,
        description,
        location,
        salary,
        ownerId,
        link,
        notes,
        order,
      },
    });
  },

  addJobStatus: async (data) => {
    if (!checkOnline()) {
      return;
    }

    await postRequest({ url: `job-status`, data }).then(({ data }) => {
      const userId = getUserId();
      toast.success("Status created");
      if (userId) {
        set({
          loading: false,
          statuses: [
            { jobStatusId: "none", jobStatus: "Saved Jobs", ownerId: userId },
          ].concat(data),
        });
      }
    });
  },

  deleteJobStatus: async (jobStatusId: string) => {
    if (!checkOnline()) {
      return;
    }

    await deleteRequest({ url: `job-status/${jobStatusId}` }).then(
      ({ data }) => {
        toast.success("Status deleted");

        const userId = getUserId();

        if (userId) {
          set({
            loading: false,
            statuses: [
              { jobStatusId: "none", jobStatus: "Saved Jobs", ownerId: userId },
            ].concat(data),
          });
        }
      }
    );
  },
}));
