import styled from "@emotion/styled";

export const PrivacyPolicyCss = styled.div`
  height: 100%;
  width: 1176px;
  padding: 120px 100px;
  overflow-y: auto;
`;

export const Title = styled.h2`
  font-size: 39px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 24px;
  font-weight: 400;
`;

export const MainText = styled(Text)`
  margin-bottom: 80px;
`;

export const ItemName = styled(Text)`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const List = styled.ul`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
`;

export const ListItem = styled.li``;
