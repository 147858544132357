import styled from "@emotion/styled";
import { secondaryWhiteText, mainDark, whiteText } from "../../utils";

export const Wrap = styled.div`
  padding: 16px;
  min-width: 400px;
  border: none;
  border-radius: 16px;
  background-color: ${secondaryWhiteText};
  text-align: left;
  cursor: pointer;
`;

export const Text = styled.p`
  border-radius: 16px;
  border: 1px dashed ${secondaryWhiteText};
  background-color: ${mainDark};
  color: ${secondaryWhiteText};
  padding: 16px;
  font-size: 20px;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 20px;
  color: ${whiteText};
`;
