import Button from "../Button";
import {
  HeaderCss,
  Logo,
  LogoStrong,
  NavMenu,
  TypoA,
  TypoLink,
} from "./Header.styled";
import { useAuthStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../utils";
import { useLocation } from "react-router-dom";

function Header() {
  const { logout, cancelSubscription } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    logout();
    navigate("/login");
  };

  const user = getUser();
  if (user && user.subscription) {
    if (user.subscription === "premium" && user.subscriptionEnd < new Date()) {
      cancelSubscription(user.id);
    }
  }

  return (
    <HeaderCss>
      <Logo to={"/"}>
        <LogoStrong>Track</LogoStrong>MyJobs
      </Logo>
      <NavMenu>
        {location.pathname === "/" && (
          <>
            <TypoA href="/#features">Features</TypoA>
            <TypoA href="/#about-us">About Us</TypoA>
            <TypoA href="/#faq">FAQ</TypoA>
          </>
        )}

        <div onClick={() => navigate("/payment-plans")}>
          <TypoLink>Pricing Plans</TypoLink>
        </div>

        <div onClick={() => navigate("/privacy-policy")}>
          <TypoLink>Privacy Policy</TypoLink>
        </div>
      </NavMenu>

      {user && <Button text="Logout" onClick={handleClick} />}
    </HeaderCss>
  );
}

export default Header;
