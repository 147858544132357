import { useState } from "react";
import { Answer, Question } from "./QuestionDropDown.styled";

type QuestionDropDownProps = { q: { q: string; a: string } };

const QuestionDropDown: React.FC<QuestionDropDownProps> = ({ q }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <div>
        <Question
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {q.q}
        </Question>

        {isOpen && <Answer>{q.a}</Answer>}
      </div>
    </>
  );
};

export default QuestionDropDown;
