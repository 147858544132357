import styled from "@emotion/styled";
import { green, tertiaryDark, whiteText } from "../../utils";
import { NavLink } from "react-router-dom";

export const SidebarCss = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid ${tertiaryDark};
  width: 100%;
  padding-bottom: 0;
`;

export const LinksWrap = styled.div`
  padding: 41px 0 0 56px;

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

export const Link = styled(NavLink)`
  color: ${whiteText};
  font-size: 20px;
`;

export const Svg = styled.svg`
  fill: ${green};
`;

export const Payment = styled.div`
  height: 56px;
  text-align: center;
  background-color: ${green};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Alert = styled(Payment)`
  background-color: red;
  color: ${whiteText};
`;
export const PaymentText = styled.p`
  text-align: center;
  margin-bottom: 5px;
  font-size: 16px;
  color: ${whiteText};
`;

export const PaymentLink = styled(NavLink)`
  color: ${whiteText};
`;

export const PaymentSvg = styled.svg`
  fill: currentColor;
`;
