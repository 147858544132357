import { useEffect, useState } from "react";
import sprite from "../../assets/icons/symbol-defs.svg";
import Button from "../../components/Button";
import {
  Wrap,
  HeroTextWrap,
  HeroWrap,
  Card,
  FeatureText,
  FreePlanWrap,
  HeroSubtitle,
  PlanName,
  PlanCost,
  PremiumPlanCount,
  HeroTitle,
  FeatureTextWrap,
  FeatureList,
  FreePlanText,
  PremiumPlanWrap,
  BestChoice,
  PlanCostBold,
  PremiumPlanText,
  PremiumPlanCost,
  CheckSubWrap,
  CheckSubText,
  CheckSubTextActive,
  PlanTextWords,
} from "./Payment.styled";
import { getUser } from "../../utils";
import { useAuthStore } from "../../store";
import { useNavigate } from "react-router-dom";

const Payment: React.FC = () => {
  const [subType, setSubType] = useState<"monthly" | "annually">("monthly");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const payment = useAuthStore((state) => state.payment);
  const navigate = useNavigate();

  useEffect(() => {
    const user = getUser();

    if (user) {
      setIsLoggedIn(true);
    }

    if (!user) {
      setIsLoggedIn(false);
    }

    if (isLoggedIn && user.subscription === "premium") {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [setIsLoggedIn]);

  const handleSubTypeClick = () => {
    if (subType === "monthly") {
      setSubType("annually");
    }
    if (subType === "annually") {
      setSubType("monthly");
    }
  };

  const handlePayClick = (subType: "annually" | "monthly"): void => {
    if (!isLoggedIn) {
      return;
    }
    payment(subType);
  };

  return (
    <Wrap>
      <HeroTextWrap>
        <HeroTitle>Pricing Plans</HeroTitle>
        <HeroSubtitle>
          Take control of your job search with TrackMyJobs
        </HeroSubtitle>
      </HeroTextWrap>

      <HeroWrap>
        <Card>
          <FreePlanWrap>
            <PlanName>Starter</PlanName>
            <FreePlanText>Free</FreePlanText>
            <PlanCost>Free</PlanCost>
          </FreePlanWrap>

          <FeatureList>
            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#done`}></use>
              </svg>

              <FeatureText>Chrome extension browser</FeatureText>
            </FeatureTextWrap>

            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#done`}></use>
              </svg>

              <FeatureText>Job tracker board</FeatureText>
            </FeatureTextWrap>

            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#done`}></use>
              </svg>

              <FeatureText>Full job description</FeatureText>
            </FeatureTextWrap>

            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#done`}></use>
              </svg>

              <FeatureText>Note feature</FeatureText>
            </FeatureTextWrap>

            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#cancel`}></use>
              </svg>

              <FeatureText>Unlimited jobs on board</FeatureText>
            </FeatureTextWrap>

            <FeatureTextWrap>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#cancel`}></use>
              </svg>

              <FeatureText>Unlimited selective networking</FeatureText>
            </FeatureTextWrap>
          </FeatureList>
        </Card>

        {subType === "monthly" && (
          <Card>
            <PremiumPlanWrap>
              <BestChoice>Best Choice</BestChoice>
              <PlanName>Premium</PlanName>
              <PremiumPlanText>
                <PremiumPlanCount>$6.50</PremiumPlanCount>{" "}
                <PlanTextWords>/Per Month</PlanTextWords>
              </PremiumPlanText>

              <CheckSubWrap onClick={handleSubTypeClick}>
                <CheckSubTextActive>Monthly</CheckSubTextActive>
                <CheckSubText>Annually</CheckSubText>
              </CheckSubWrap>
              <PremiumPlanCost> </PremiumPlanCost>
            </PremiumPlanWrap>

            <FeatureList>
              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Chrome extension browser</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Job tracker board</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Full job description</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Note feature</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Unlimited jobs on board</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Unlimited selective networking</FeatureText>
              </FeatureTextWrap>
            </FeatureList>

            {isLoggedIn && (
              <Button
                text="Pay Monthly"
                width="336px"
                btnType="filled"
                onClick={() => {
                  handlePayClick(subType);
                }}
              />
            )}
          </Card>
        )}

        {subType === "annually" && (
          <Card>
            <PremiumPlanWrap>
              <BestChoice>Best Choice</BestChoice>
              <PlanName>Premium</PlanName>
              <PremiumPlanText>
                <PremiumPlanCount>$65.0</PremiumPlanCount>{" "}
                <PlanTextWords>/Per Year</PlanTextWords>
              </PremiumPlanText>

              <CheckSubWrap onClick={handleSubTypeClick}>
                <CheckSubText>Monthly</CheckSubText>
                <CheckSubTextActive>Annually</CheckSubTextActive>
              </CheckSubWrap>

              <PremiumPlanCost>
                Save <PlanCostBold> $13.0 </PlanCostBold>today
              </PremiumPlanCost>
            </PremiumPlanWrap>

            <FeatureList>
              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Chrome extension browser</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Job tracker board</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Full job description</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Note feature</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Unlimited jobs on board</FeatureText>
              </FeatureTextWrap>

              <FeatureTextWrap>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#done`}></use>
                </svg>

                <FeatureText>Unlimited selective networking</FeatureText>
              </FeatureTextWrap>
            </FeatureList>

            {isLoggedIn && (
              <Button
                text="Pay Annually"
                width="336px"
                btnType="filled"
                onClick={() => {
                  handlePayClick(subType);
                }}
              />
            )}
          </Card>
        )}
      </HeroWrap>
    </Wrap>
  );
};

export default Payment;
