import {
  ItemName,
  List,
  ListItem,
  MainText,
  PrivacyPolicyCss,
  Text,
  Title,
} from "./PrivacyPolicy.styled";

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyCss>
      <Title>TRACK MY JOB PRIVACY POLICY</Title>
      <MainText>
        TrackMyJobs (“Company,” “we,” “our,” or “us”) values your privacy and is
        dedicated to protecting it in compliance with this privacy policy
        (“Privacy Policy”). This Privacy Policy outlines the types of
        information we may collect from job seekers (“you” or “user”) when you
        use our web-based service (“Services”) or that you may provide when
        visiting the website https://trackmyjobs.ca/ (our “Website”). We only
        request your information when it is truly necessary.
      </MainText>

      <Title>How TrackMyJobs Collects and Uses User Data</Title>
      <List>
        <ListItem>
          <ItemName>Collection:</ItemName>
          <Text>
            TrackMyJobs only collects job-related information that users
            manually save while interacting with job boards and career pages.
            This includes data such as job titles, company names, application
            dates, and other job-related details provided by the users. No
            personal or sensitive data is collected unless explicitly entered by
            the user.
          </Text>
        </ListItem>

        <ListItem>
          <ItemName>Usage:</ItemName>
          <Text>
            The data is used solely to help users manage and track their job
            applications in their personal job board. All data is stored locally
            on the user’s device or in the cloud based on user preferences and
            the user has full control to edit or delete this information at any
            time.
          </Text>
        </ListItem>

        <ListItem>
          <ItemName>Sharing:</ItemName>
          <Text>
            TrackMyJobs does not share user data with any outside parties. The
            extension ensures that all data remains private and stored only on
            the users device or on our secure cloud server.
          </Text>
        </ListItem>
      </List>
    </PrivacyPolicyCss>
  );
};

export default PrivacyPolicy;
