import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormWrapSettings,
  Form,
  InputWrap,
  InputError,
  ButtonsWrap,
  InputsWrap,
} from "./Forms.styled";
import Button from "../Button";
import { FirstWrap } from "../Modal/Modal.styled";
import TextInput from "../Modal/InputText";
import { getUser, getUserId } from "../../utils";
import { useAuthStore } from "../../store";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  role: Yup.string().required("Role is required"),
  location: Yup.string().required("Location is required"),
  portfolioLink: Yup.string().required("Portfolio is required"),
});

export const SettingsForm: React.FC = () => {
  const { email, firstName, lastName, role, location, portfolioLink } =
    getUser();

  const updateUser = useAuthStore((state) => state.updateUser);

  const formik = useFormik({
    initialValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      role: role,
      location: location,
      portfolioLink: portfolioLink,
    },

    validationSchema: validationSchema,
    onSubmit: async ({
      email,
      firstName,
      lastName,
      role,
      location,
      portfolioLink,
    }) => {
      const id = getUserId();
      if (id) {
        updateUser(id, {
          email,
          firstName,
          lastName,
          role,
          location,
          portfolioLink,
        });
      }
    },
  });

  return (
    <FormWrapSettings>
      <Form onSubmit={formik.handleSubmit}>
        <InputsWrap>
          <FirstWrap>
            <InputWrap>
              <TextInput
                label="Email"
                icon=""
                placeholder="Email"
                type="text"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              {formik.touched.email && formik.errors.email ? (
                //@ts-ignore
                <InputError>{formik.errors.email}</InputError>
              ) : null}
            </InputWrap>

            <InputWrap>
              <TextInput
                label="First Name"
                icon=""
                placeholder="First Name"
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />

              {formik.touched.firstName && formik.errors.firstName ? (
                //@ts-ignore
                <InputError>{formik.errors.firstName}</InputError>
              ) : null}
            </InputWrap>
          </FirstWrap>

          <InputWrap>
            <TextInput
              label="Last Name"
              icon=""
              placeholder="Last Name"
              type="text"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />

            {formik.touched.lastName && formik.errors.lastName ? (
              //@ts-ignore
              <InputError>{formik.errors.lastName}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <TextInput
              label="Location"
              icon=""
              placeholder="Location"
              type="text"
              name="location"
              onChange={formik.handleChange}
              value={formik.values.location}
            />

            {formik.touched.location && formik.errors.location ? (
              //@ts-ignore
              <InputError>{formik.errors.location}</InputError>
            ) : null}
          </InputWrap>
          <FirstWrap>
            <InputWrap>
              <TextInput
                label="Role"
                icon=""
                placeholder="Role"
                type="text"
                name="role"
                onChange={formik.handleChange}
                value={formik.values.role}
              />

              {formik.touched.role && formik.errors.role ? (
                //@ts-ignore
                <InputError>{formik.errors.role}</InputError>
              ) : null}
            </InputWrap>

            <InputWrap>
              <TextInput
                label="Portfolio Link"
                icon=""
                placeholder="Portfolio Link"
                type="text"
                name="portfolioLink"
                onChange={formik.handleChange}
                value={formik.values.portfolioLink}
              />

              {formik.touched.portfolioLink && formik.errors.portfolioLink ? (
                //@ts-ignore
                <InputError>{formik.errors.portfolioLink}</InputError>
              ) : null}
            </InputWrap>
          </FirstWrap>
        </InputsWrap>

        <ButtonsWrap>
          <Button text="Update" type="submit" bold btnType="filled" />
        </ButtonsWrap>
      </Form>
    </FormWrapSettings>
  );
};
