import { ButtonHTMLAttributes } from "react";
import { TextButtonCss, Svg } from "./Button.styled";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  bold?: boolean;
  icon?: string;
  width?: string;
  height?: string;
  as?: "button" | "a";
  href?: string;
  btnType?: "filled" | "empty";
}

export default function TextButton({
  text,
  onClick,
  disabled,
  type = "button",
  btnType = "empty",
  icon,
  width = "162px",
  height = "48px",
  bold = false,
  as = "button",
  href,
}: Props) {
  return (
    <TextButtonCss
      onClick={onClick}
      disabled={disabled}
      type={type}
      bold={bold}
      width={width}
      height={height}
      btnType={btnType}
      as={as}
      {...(as === "a" ? { href } : {})}
    >
      {icon && (
        <Svg width="24" height="24">
          <use xlinkHref={icon}></use>
        </Svg>
      )}
      <span>{text}</span>
    </TextButtonCss>
  );
}
