import React, { useState, SyntheticEvent } from "react";
import sprite from "../../assets/icons/symbol-defs.svg";

import {
  Backdrop,
  Form,
  Tabs,
  Tab,
  Content,
  Header,
  Info,
  Buttons,
  InfoTitle,
  FormWrap,
  InputWrap,
  FirstWrap,
  TextInputLabel,
  NotesTextArea,
} from "./Modal.styled";
import Button from "../Button";
import InputText from "./InputText";
import TextArea from "./TextArea";
import { useJobsStore } from "../../store";
import { getUserId } from "../../utils";
import * as Yup from "yup";

const JobSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job title is required"),
  company: Yup.string().required("Company is required"),
  salary: Yup.string(),
  location: Yup.string(),
  description: Yup.string(),
  notes: Yup.string(),
  link: Yup.string().required("Link is required"),
});

type ModalProps = { jobStatusId: string; onClose: () => void };

const Modal: React.FC<ModalProps> = ({ onClose, jobStatusId }) => {
  const { addJob } = useJobsStore((state) => state);

  const [activeTab, setActiveTab] = useState<"edit" | "networking" | "notes">(
    "edit"
  );

  const [formValues, setFormValues] = useState({
    jobTitle: "",
    company: "",
    salary: "",
    location: "",
    description: "",
    notes: "",
    link: "",
    jobStatusId: jobStatusId,
    ownerId: getUserId(),
  });

  const [desc, setDesc] = useState<string>(
    "<h2>About Job</h2>" + formValues.description
  );

  const [errors, setErrors] = useState<any>({});

  const handleChanges = (element: string) => {
    setDesc(element);
    setFormValues((prevValues) => ({
      ...prevValues,
      description: element,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validateField(name, value);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validateField(name, value);
  };

  const validateField = async (name: string, value: any) => {
    try {
      await Yup.object().shape(value);
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    } catch (err: any) {
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: err.message }));
    }
  };

  const onSubmit = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();
    try {
      await JobSchema.validate(formValues, { abortEarly: false });
      const ownerId = getUserId();

      if (ownerId) {
        addJob({ ...formValues, ownerId });
      }

      onClose();
    } catch (err: any) {
      const validationErrors: any = {};
      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  return (
    <Backdrop>
      <Form onClick={(e) => e.stopPropagation()} onSubmit={onSubmit}>
        <Header>
          <Info>
            <InfoTitle>Create Job Card</InfoTitle>
          </Info>

          <Buttons>
            <Button
              text="Back"
              width="120px"
              type="button"
              icon={`${sprite}#return`}
              onClick={() => onClose()}
            />
            <Button
              text="Save"
              btnType={"filled"}
              width="120px"
              icon={`${sprite}#favorite`}
              type="submit"
            />
          </Buttons>
        </Header>

        <Tabs>
          <Tab
            type="button"
            isActive={activeTab === "edit"}
            onClick={() => setActiveTab("edit")}
          >
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#box_edit`}></use>
            </svg>
            Edit
          </Tab>

          <Tab
            type="button"
            isActive={activeTab === "notes"}
            onClick={() => setActiveTab("notes")}
          >
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#stylus`}></use>
            </svg>
            Notes
          </Tab>
        </Tabs>

        <Content>
          {activeTab === "edit" && (
            <FormWrap>
              <InputWrap>
                <FirstWrap>
                  <InputText
                    label="Job Title"
                    name="jobTitle"
                    type="text"
                    icon={`${sprite}#work`}
                    value={formValues.jobTitle}
                    onChange={handleInputChange}
                  />
                  {errors.jobTitle && <div>{errors.jobTitle}</div>}
                  <InputText
                    label="Company"
                    name="company"
                    type="text"
                    icon={`${sprite}#factory`}
                    value={formValues.company}
                    onChange={handleInputChange}
                  />
                  {errors.company && <div>{errors.company}</div>}
                </FirstWrap>

                <InputText
                  label="Post URL"
                  name="link"
                  type="text"
                  icon={`${sprite}#link`}
                  value={formValues.link}
                  onChange={handleInputChange}
                />
                {errors.link && <div>{errors.link}</div>}

                <FirstWrap>
                  <InputText
                    label="Salary"
                    name="salary"
                    type="text"
                    icon={`${sprite}#universal_currency_alt`}
                    value={formValues.salary}
                    onChange={handleInputChange}
                  />
                  {errors.salary && <div>{errors.salary}</div>}
                  <InputText
                    label="Location"
                    name="location"
                    type="text"
                    icon={`${sprite}#distance`}
                    value={formValues.location}
                    onChange={handleInputChange}
                  />
                  {errors.location && <div>{errors.location}</div>}
                </FirstWrap>

                <div>
                  <TextInputLabel>Description</TextInputLabel>
                  <TextArea element={desc} onChanges={handleChanges} />
                  {errors.description && <div>{errors.description}</div>}
                </div>
              </InputWrap>
            </FormWrap>
          )}

          {activeTab === "notes" && (
            <NotesTextArea
              name="notes"
              value={formValues.notes}
              onChange={handleNotesChange}
            />
          )}
          {errors.notes && <div>{errors.notes}</div>}
        </Content>
      </Form>
    </Backdrop>
  );
};

export default Modal;
