import styled from "@emotion/styled";
import { mainDark, whiteText } from "../../utils";

type SectionProps = {
  black: boolean;
  rounded: boolean;
};

export const Div = styled.div`
  background-color: ${mainDark};
`;

export const SectionCss = styled.section<SectionProps>`
  width: 100%;
  padding: 133px 60px;
  background-color: ${({ black }) => (black ? mainDark : whiteText)};
  ${({ rounded }) => rounded && "border-radius:36px;"}
`;
