import styled from "@emotion/styled";

export const SettingsPage = styled.div`
  padding: 0 150px;
  margin-bottom: 20px;
`;

export const MainInfo = styled.div``;

export const Name = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Role = styled.p`
  font-size: 16px;
  margin-bottom: 12px;
`;

export const InfoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
`;

export const InfoText = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

export const SecondWrap = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
