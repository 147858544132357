import { TextAreaCss } from "./TextArea.styled";

type TextAreaProps = {
  element: React.ReactNode;
  onChanges: (element: string) => void;
};

const TextArea: React.FC<TextAreaProps> = ({ element, onChanges }) => {
  const handleChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChanges(e.target.innerHTML);
  };

  const createMarkup = () => {
    return {
      __html: element as string,
    };
  };

  return (
    <TextAreaCss>
      <div onChange={handleChange} dangerouslySetInnerHTML={createMarkup()} />
    </TextAreaCss>
  );
};

export default TextArea;
