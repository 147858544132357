import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/";
import { useAuthStore } from "../store";
import { useEffect } from "react";
import { getRefreshToken } from "../utils";

const ProtectedRoute = () => {
  const { refresh } = useAuthStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      refresh(refreshToken);
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  const { accessToken } = useAuth();

  return accessToken ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
