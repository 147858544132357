import { Route, Routes } from "react-router-dom";

import Dashboard from "../../pages/Dashboard";
import Settings from "../../pages/Settings";
import Login from "../../pages/Login";
import { Layout } from "../Layout";
import { PaymentLayout } from "../Layout";
import ProtectedRoute from "../ProtectedRoute";
import Register from "../../pages/Register";

import Payment from "../../pages/Payment";
import Home from "../../pages/Home";
import { useEffect } from "react";
import PrivacyPolicy from "../../pages/PrivacyPolicy";

function App() {
  useEffect(() => {
    document.title = "Track My Jobs";
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Home />} />

      <Route element={<PaymentLayout />}>
        <Route index path="payment-plans" element={<Payment />} />
      </Route>

      <Route element={<PaymentLayout />}>
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Layout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="/settings" element={<Layout />}>
          <Route index element={<Settings />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
