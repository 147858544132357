import styled from "@emotion/styled";
import { mainDark, tertiaryDark, whiteText } from "../../utils";

export const Wrap = styled.div``;

export const List = styled.ul`
  display: flex;
  gap: 23px;
  align-items: start;
`;

export const Column = styled.li`
  padding: 16px;
  background-color: ${tertiaryDark};
  width: 400px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleWrap = styled.div`
  background-color: ${mainDark};
  border-radius: 12px;
  color: ${whiteText};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 20px;
  padding: 16px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  width: 90px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const Button = styled.button`
  background-color: transparent;
  color: ${whiteText};
  font-size: 10px;
  border: none;
`;

export const CardsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardsItem = styled.li`
  background-color: ${mainDark};
  padding: 16px;
  border-radius: 12px;
`;

export const EmptyMessage = styled.p`
  color: ${whiteText};
  text-align: center;
  margin: 16px 0;
`;
