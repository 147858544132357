import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthStore } from "../../store";
import {
  FormWrap,
  FormTitle,
  Form,
  InputWrap,
  FormInput,
  InputError,
  InputsWrap,
  ButtonsWrap,
} from "./Forms.styled";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const RegisterForm = () => {
  const register = useAuthStore((state) => state.register);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (
      { firstName, lastName, email, phoneNumber, password },
      { setSubmitting }
    ) => {
      try {
        await register({ firstName, lastName, email, phoneNumber, password });
        setSubmitting(false);
        navigate("/login");
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  return (
    <FormWrap>
      <FormTitle>Sign Up</FormTitle>

      <Form onSubmit={formik.handleSubmit}>
        <InputsWrap>
          <InputWrap>
            <FormInput
              placeholder="First Name"
              type="text"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <InputError>{formik.errors.firstName}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Last Name"
              type="text"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <InputError>{formik.errors.lastName}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Email"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <InputError>{formik.errors.email}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Phone Number with country code"
              type="text"
              name="phoneNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <InputError>{formik.errors.phoneNumber}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Password"
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <InputError>{formik.errors.password}</InputError>
            ) : null}
          </InputWrap>

          <InputWrap>
            <FormInput
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <InputError>{formik.errors.confirmPassword}</InputError>
            ) : null}
          </InputWrap>
        </InputsWrap>
        <ButtonsWrap>
          <Button
            text="Register"
            disabled={formik.isSubmitting}
            type="submit"
            width="100%"
            bold
            btnType="filled"
          />

          <Button
            text="To Login Page"
            type="button"
            width="100%"
            bold
            btnType="filled"
            onClick={() => {
              navigate("/login");
            }}
          />
        </ButtonsWrap>
      </Form>
    </FormWrap>
  );
};
