import React, { useState } from "react";
import { Wrap, Text, Input } from "./AddStatus.styled";
import { useAuthStore, useJobsStore } from "../../store";

const AddStatus: React.FC = () => {
  const { addJobStatus } = useJobsStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const [isEditing, setIsEditing] = useState(false);

  const onClick = () => {
    setIsEditing(true);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(false);
    const status = e.target.value;

    if (status.trim() !== "") {
      addJobStatus({
        ownerId: user.id,
        jobStatus: status,
      });
      e.target.value = "";
    }
  };

  return (
    <Wrap>
      {isEditing ? (
        <Text>
          <Input type="text" onBlur={onBlur} autoFocus />
        </Text>
      ) : (
        <Text onClick={onClick}>+ Add Board</Text>
      )}
    </Wrap>
  );
};

export default AddStatus;
