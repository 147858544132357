import { useNavigate } from "react-router-dom";
import { SettingsForm } from "../../components/Forms/";
import {
  SettingsPage,
  Name,
  Role,
  InfoWrap,
  InfoText,
  SecondWrap,
} from "./Settings.styled";
import { getUser } from "../../utils";
import Button from "../../components/Button";

const Settings = () => {
  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    role,
    location,
    portfolioLink,
    email,
    subscription,
    subscriptionEnd,
    suspended,
  } = getUser();
  console.log(getUser());

  const handleUpgrade = () => {
    navigate("/payment");
  };

  return (
    <>
      <SettingsPage>
        <div>
          <Name>{`${firstName} ${lastName}`}</Name>
          <Role>{role}</Role>

          <InfoWrap>
            <InfoText>{location}</InfoText>
            <InfoText>{portfolioLink}</InfoText>
            <InfoText>{email}</InfoText>
          </InfoWrap>
        </div>
      </SettingsPage>

      <SecondWrap>
        <SettingsForm />
        <div>
          <p>
            You are currently on the <span>{subscription}</span> plan
          </p>

          {subscription === "premium" && (
            <div>
              <p>Your Plan Info:</p>
              <p>
                Your subscription is active until{" "}
                {new Intl.DateTimeFormat("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }).format(new Date(Number(subscriptionEnd)))}
              </p>

              <p>Is your subscription stopped {suspended ? "Yes" : "No"}</p>
            </div>
          )}

          {subscription === "Free" && (
            <Button text="Upgrade" onClick={handleUpgrade} />
          )}
        </div>
      </SecondWrap>
    </>
  );
};

export default Settings;
