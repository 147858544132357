import React from "react";
import sprite from "../../assets/icons/symbol-defs.svg";
import { JobLinkText, Svg } from "./JobLink.styled";

type JobLinkProps = { link: string };

const JobLink: React.FC<JobLinkProps> = ({ link }) => {
  return (
    <JobLinkText href={link}>
      <Svg width="24" height="24">
        <use xlinkHref={`${sprite}#link`}></use>
      </Svg>
      {link}
    </JobLinkText>
  );
};

export default JobLink;
