import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const MainContent = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: grid;
  max-height: calc(100vh - 101px);
  padding: 24px;
  height: 100%;
  overflow: auto;
`;

export const PaymentMainContent = styled.div`
  height: 100%;
`;

export const PaymentContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
